<template>
  <div class="refundInfo">
    <div class="infoTitle">退款单信息</div>
    <div class="infos">
      <div class="minInfo">退款单号：{{ returnMessage.cpcn_return_id }}</div>
      <div class="minInfo">
        生成时间：{{ returnMessage.return_pay_create_time }}
      </div>
      <div class="minInfo">会员：{{ returnMessage.shop_name }}</div>
      <div class="minInfo">共享供应商：{{ returnMessage.supplier_name }}</div>
      <div class="minInfo">销售供应商：{{ returnMessage.sale_name }}</div>
      <div class="minInfo">
        退款流水号：{{ returnMessage.cpcn_return_trade_no }}
      </div>
      <div class="minInfo">
        退款时间：{{ returnMessage.cpcn_return_pay_time }}
      </div>
      <div class="minInfo">
        退款状态：
        <span v-if="returnMessage.status_ == 10">受理成功</span>
        <span v-if="returnMessage.status_ == 20">成功</span>
        <span v-if="returnMessage.status_ == 30">失败</span>
        <span v-if="returnMessage.status_ == 40">退票</span>
      </div>
      <div class="minInfo">售后单号：{{ returnMessage.after_sales_id }}</div>
      <div class="minInfo">售后时间：{{ returnMessage.after_sales_time }}</div>
      <div class="minInfo">退款金额：{{ returnMessage.return_total }}</div>
      <div class="minInfo">
        运费退款：{{ returnMessage.returned_freight_amount_total }}
      </div>
      <div class="minInfo">订单号：{{ returnMessage.order_id }}</div>
      <div class="minInfo">下单时间：{{ returnMessage.order_time }}</div>
      <div class="minInfo">主订单号：{{ returnMessage.group_id }}</div>
    </div>
    <div class="dataTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="date"
          label="序号"
          width="50"
          fixed
          type="index"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="date" label="商品ID" align="center" width="150">
          <template slot-scope="scope">
            <p>{{ scope.row.goods_id }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="商品名称"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.goods_name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="规格" align="center" width="150">
          <template slot-scope="scope">
            <p>{{ scope.row.goods_specification }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="上产厂家"
          align="center"
          width="250"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.manufacturer }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="销售单价 (元)"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.actual_unit_price }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="退货数量"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.after_sale_quantity }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="退款总手续费"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.platform_handling_fee_total }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="退款总佣金" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.commission_total }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="退款小计 (元)" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.return_total }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <pagination
        v-show="pageTotal > 0"
        :total="pageTotal"
        :page.sync="currentPage1"
        :limit.sync="pageSize"
        @pagination="getReturnMessage"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const settlement = createNamespacedHelpers("settlement");
export default {
  data() {
    return {
      tableData: [],
      returnId: "0",
      returnMessage: {},
      pageTotal: 0,
      currentPage1: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...commonIndex.mapState(["AreaList", "mainHright"]),
    ...settlement.mapState(["returnInfo"]),
  },
  methods: {
    ...settlement.mapActions(["returnOrderInfo"]),
    async getReturnMessage() {
      let data = await this.returnOrderInfo({
        after_sales_id: this.returnId,
        page: this.currentPage1,
        perPage: this.pageSize,
      });
      this.tableData = data.data.list.data;
      this.pageTotal = data.data.list.total;
      this.returnMessage = data.data.info;
    },
    // 换页
    handleCurrentChange(item) {
      this.currentPage1 = item;
      this.getReturnMessage();
    },
  },
  created() {
    this.returnId = this.$route.query.id;
    this.getReturnMessage();
  },
};
</script>

<style lang="scss" scoped>
.refundInfo {
  .infos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .minInfo {
      width: 20%;
      line-height: 30px;
      font-size: 14px;
    }
  }
  .dataTable {
    margin-top: 40px;
  }
  .footer {
    text-align: right;
    margin-top: 20px;
  }
}
</style>
